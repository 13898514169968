import { useState, useEffect, React } from "react";
// import cuplogo from "./DAL/FIFA-World-Cup-2022-Qatar-emblem.webp";
import cuplogo from "./DAL/goalogo.png";

import champions from "./DAL/uefa-white.png";
import "./Header.css";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import Searchbox from "./Searchbox";
import { useHistory } from "react-router-dom";

function Header({ login, isLogged }) {
  const targetTime = moment("2023-06-10");
  const [currentTime, setCurrentTime] = useState(moment());
  const timeBetween = moment.duration(targetTime.diff(currentTime));

  const history = useHistory();

  useEffect(() => {
    isLogged && history.push("/game");
  }, [isLogged, history]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container fluid>
     <Row className="logo-row">
        <Col align="right">
          <img src={cuplogo} className="logo-image" alt=""></img>
        </Col>
      </Row>
      <Row>
        <Col align="left">
          <span className="qatar-word">Istanbul</span>
          <span ><img src={champions} className="uefa-img"/></span>
          
        </Col>
      </Row>
      <Row>
        <Col align="left">
          <span className="see-you-txt">See You in 2023</span>
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <h1 className="ribbon" dir="ltr">
            <strong className="ribbon-content">
              <i className="fas fa-plane plane-icon"></i>
              اربح تذاكر مجانية أبطال أوروبا ٢٠٢٣
              <i className="fas fa-futbol plane-icon-bottom"></i>
            </strong>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Searchbox userLogin={login} />
        </Col>
      </Row>
      <Row>
        <div className="countdown">
          <Row>
            <Col align="center">
              <span className="final-txt">Ataturk Final</span>
            </Col>
          </Row>
          <Row>
            <Col align="center">
              <span className="diamond">◆</span>

              <i
                className="fas fa-2x fa-futbol"
                style={{ margin: "0.5rem" }}
              ></i>
              <span className="diamond">◆</span>
            </Col>
          </Row>
          <Row>
            <Col align="center">
              <p className="counter">
                <span>
                  {timeBetween.months() > 0 ? `${timeBetween.months()}m ` : ""}
                </span>
                <span className="diamond-time">◆</span>
                <span>{timeBetween.days()}d </span>
                <span className="diamond-time">◆</span>
                <span>{timeBetween.hours()}h </span>
                <span className="diamond-time">◆</span>
                <span>{timeBetween.minutes()}min </span>
                <span className="diamond-time">◆</span>
                <span>{timeBetween.seconds()}s </span>
              </p>
            </Col>
          </Row>
        </div>
      </Row>
    </Container>
  );
}

export default Header;
