import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/qatar2022.otf";
import "./fonts/qatarabic/QatarabicBold.ttf";
import "./fonts/qatarabic/QatarabicHeavy.ttf";
import "./fonts/qatarabic/QatarabicMedium.otf";
import "./fonts/uefont/UefaBold.otf";
import "./fonts/uefont/UefaReg.otf";

import clip from "./DAL/videotry.mp4";

ReactDOM.render(
  <>
    <video autoPlay loop muted className="background-video">
      <source src={clip} type="video/mp4" />
      <source src={clip} type="video/ogg" />
    </video>
    <App />
  </>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
