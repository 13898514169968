import { useState, React } from "react";
import "./App.css";
import Header from "./Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Game from "./Game";
import Manager from "./Manager";
import Stats from "./Stats";

function App() {
  const [login, setLogin] = useState({ state: false, name: null });
  const [data, setData] = useState({ user: false, info: false });
  const userLogin = async (input) => {
    await delay(6000);
    setLogin({ state: true, name: input });
  };

  const delay = async (ms) => new Promise((res) => setTimeout(res, ms));

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Header login={userLogin} isLogged={login.state} />
          </Route>
          <Route exact path="/game">
            <Game user={login.name} />
          </Route>
          <Route exact path="/manager">
            <Manager sendData={setData} />
          </Route>
          <Route exact path="/stats">
            <Stats data={data} sendData={setData} />
          </Route>
          <Route exact path="/:something">
            <Header login={userLogin} isLogged={login.state} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
