import { React, useState, useEffect } from "react";

import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import "./Searchbox.css";

function Searchbox({ userLogin }) {
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const [userDeatils, setUserDetails] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [endInput, setEndInput] = useState(false);

  const sendinput = () => {
    if (!endInput) {
      setEndInput(true);
      userLogin(userDeatils);
    }
  };

  useEffect(() => {
    if (userDeatils.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [userDeatils]);

  return (
    <Container className="search-box">
      <Form onKeyDown={handleKeyDown}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Row>
            <Col align="center">
              <Form.Control
                placeholder="اسم المشترك"
                className="placeholder"
                onChange={(e) => {
                  setUserDetails(e.target.value);
                }}
                disabled={endInput ? true : false}
              />
            </Col>
          </Row>
          <Form.Text className="form-txt" dir="rtl">
            سجل اسمك حتى تشارك في القرعة
          </Form.Text>
        </Form.Group>
      </Form>

      {!endInput && (
        <button
          className="enter-btn"
          disabled={!isValid || endInput ? true : false}
          onClick={() => {
            sendinput();
          }}
        >
          PLAY <i className="fas fa-play play-icon"></i>
        </button>
      )}
      <Row>
        <Col>
          {endInput && (
            <>
              <Spinner animation="grow" className="spinner-name" />{" "}
              <Spinner animation="grow" className="spinner-name" />{" "}
              <Spinner animation="grow" className="spinner-name" />{" "}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Searchbox;
