import { React, useState } from "react";
import { useHistory } from "react-router-dom";

import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import "./Game.css";
import cuplogo from "./DAL/goalogo.png";
import api from "./DAL/api.js";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import Confetti from "react-confetti";
import windowDimensions from "./Windimension.js";
import FormSub from "./FormSub.js";

import Bayren from "./DAL/teams/bayren.png";
import Benfica from "./DAL/teams/benfica.png";
import Brujas from "./DAL/teams/brujas.png";
import Chelsea from "./DAL/teams/chelsea.png";
import Dortmund from "./DAL/teams/dortmund.png";
import Frankfurt from "./DAL/teams/frankfurt.png";
import Inter from "./DAL/teams/inter.png";
import Leipzig from "./DAL/teams/leipzig.png";
import Liverpool from "./DAL/teams/liverpool.png";
import Manchester from "./DAL/teams/manchestercity.png";
import Milan from "./DAL/teams/milan.png";
import Napoli from "./DAL/teams/napoli.png";
import PSG from "./DAL/teams/paris.png";
import Porto from "./DAL/teams/porto.png";
import Real from "./DAL/teams/realmadrid.png";
import Tottenham from "./DAL/teams/tottenham.png";

function Game({ user }) {
  let history = useHistory();

  if (!user) {
    history.push("/");
  }

  const [teams, setTeams] = useState(api.getDb());
  const [guess, setGuess] = useState({ finalist: [], winner: null });
  const { width, height } = windowDimensions();
  const [rolling, setRolling] = useState(false);
  const [show, setShow] = useState(false);
  const imgs = {
    Bayren: Bayren,
    Benfica: Benfica,
    Brujas: Brujas,
    Chelsea: Chelsea,
    Dortmund: Dortmund,
    Frankfurt: Frankfurt,
    Inter: Inter,
    Leipzig: Leipzig,
    Liverpool: Liverpool,
    Manchester: Manchester,
    Milan: Milan,
    Napoli: Napoli,
    PSG: PSG,
    Porto: Porto,
    Real: Real,
    Tottenham: Tottenham,
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const pickFinalists = (team) => {
    let newGuess = guess;
    if (
      !newGuess.finalist.find((item) => item === teams[team]) &&
      newGuess.finalist.length <= 1
    ) {
      newGuess.finalist.push(teams[team]);
      setGuess({ ...newGuess });
    } else {
      setRolling(true);
      setTimeout(() => {
        setRolling(false);
      }, 1000);
    }
  };

  const remFinalists = (teamIndex) => {
    let newGuess = guess;
    newGuess.finalist.splice(teamIndex, 1);
    setGuess({ ...newGuess, winner: null });
  };

  const pickWinner = (teamIndex) => {
    let newGuess = guess;
    if (newGuess.finalist.length === 2 && !newGuess.winner) {
      let newWinner = newGuess.finalist[teamIndex];
      setGuess({ ...newGuess, winner: newWinner });
    }
  };

  return (
    <Container fluid className="game-container">
      {guess.winner && (
        <Row>
          <Confetti width={width} height={height} gravity={0.3} />
        </Row>
      )}
      <Row className="logo-row">
        <Col align="right">
          <img src={cuplogo} className="logo-image" alt=""></img>
        </Col>
      </Row>
      <Row className="user-name">
        <Col>
          <i className="em em-soccer soccer-icon"></i>
          <span dir="rtl">اهلا وسهلا {user}</span>
          <i className="em em-soccer soccer-icon"></i>
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <Card style={{ width: "20rem" }} className="teams-card">
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Card.Title>
                    <span className="diamond">◆</span>
                    الفرق
                    <span className="diamond">◆</span>
                  </Card.Title>
                  <Card.Subtitle className="card-subtitle">
                    <i className="far fa-hand-point-down"></i>

                    <span> اختر فريقين تتأهل لنهائي المسابقة</span>
                  </Card.Subtitle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <SimpleBar
                    style={{ maxHeight: 160 }}
                    className="scroll-bar"
                    forceVisible="y"
                    autoHide={false}
                  >
                    <Row>
                      {teams.map((country, i) => (
                        <Col align="center" key={i}>
                          <div className="country-cointainer">
                            <Row>
                              <Col>
                                <span
                                  className={
                                    guess.finalist.find(
                                      (item) => item.engName === country.engName
                                    )
                                      ? "flag-chosen"
                                      : "flag-name"
                                  }
                                  key={i}
                                  onClick={() => pickFinalists(i)}
                                >
                                  <img
                                    src={imgs[country.engName]}
                                    className="team-logo"
                                  />
                                </span>
                              </Col>
                            </Row>
                            <Row className="country-row">
                              <Col>
                                <span
                                  className={
                                    guess.finalist.find(
                                      (item) => item.engName === country.engName
                                    )
                                      ? "country-name-chosen"
                                      : "country-name"
                                  }
                                  key={i}
                                >
                                  {country.engName}
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </SimpleBar>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="arrow-row">
        <Col>
          <i className="fas fa-2x fa-chevron-down"></i>
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <Card style={{ width: "20rem" }} className="teams-card">
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Card.Title>
                    <span className="diamond">◆</span>
                    النهائيات
                    <span className="diamond">◆</span>
                  </Card.Title>
                  <Card.Subtitle className="card-subtitle">
                    <i className="far fa-hand-point-down"></i>

                    <span> اختر الفريق الفائز</span>
                  </Card.Subtitle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col className="divider-border">
                      {guess.finalist[0] ? (
                        <Row className={rolling ? "shaking" : "else"}>
                          <Col xs={8} align="right">
                            <span
                              className="final-groups"
                              onClick={() => pickWinner(0)}
                            >
                              <img
                                src={imgs[guess.finalist[0].engName]}
                                className="team-logo"
                              />
                              {/* {guess.finalist[0].teamFlag} */}
                            </span>
                          </Col>
                          <Col xs={1} align="left">
                            <i
                              className="fas fa-trash-alt"
                              onClick={() => remFinalists(0)}
                            ></i>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col>
                            <span className="final-groups">
                              <i className="em em-question"></i>
                            </span>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col>
                      {guess.finalist[1] ? (
                        <Row className={rolling ? "shaking" : "else"}>
                          <Col xs={8} align="right">
                            <span
                              className="final-groups"
                              onClick={() => pickWinner(1)}
                            >
                              <img
                                src={imgs[guess.finalist[1].engName]}
                                className="team-logo"
                              />
                            </span>
                          </Col>
                          <Col xs={1} align="left">
                            <i
                              className="fas fa-trash-alt"
                              onClick={() => remFinalists(1)}
                            ></i>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col>
                            <span className="final-groups">
                              <i className="em em-question"></i>
                            </span>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="arrow-row">
        <Col>
          <i className="fas fa-2x fa-chevron-down"></i>
        </Col>
      </Row>
      <Row>
        <Col>
          <i className="em em-trophy winner-trophy"></i>
          {guess.winner ? (
            <span className="winner-flag">
              <img src={imgs[guess.winner.engName]} className="team-logo" />
            </span>
          ) : (
            <i className="fas fa-2x fa-question question-winner"></i>
          )}
          <i className="em em-trophy winner-trophy"></i>
        </Col>
      </Row>
      {guess.winner && (
        <>
          <Row className="submit-row">
            <Col>
              <span className="diamond">◆</span>
              <span dir="rtl">
                فائز دوري أبطال أوروبا ٢٠٢٣:
                <span className="match-winner"> {guess.winner.engName}</span>
              </span>
              <span className="diamond">◆</span>
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <button className="submit-btn" onClick={handleShow}>
                SUBMIT <i className="fas fa-check-circle"></i>
              </button>
            </Col>
          </Row>
        </>
      )}
      {show && (
        <Row>
          <Col>
            <FormSub
              close={handleClose}
              show={show}
              winner={guess.winner.engName}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default Game;
