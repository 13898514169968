import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Table, ListGroup, Button } from "react-bootstrap";
import logo from "./DAL/goalogo.png";
import "./Stats.css";
import SimpleBar from "simplebar-react";
import api from "./DAL/api";

function Stats({ data, sendData }) {
  let history = useHistory();
  const [highlight, setHighlight] = useState(0);

  useEffect(() => {
    !data.user && history.push("/");
  }, [data, history]);

  const refreshData = async () => {
    try {
      let res = await api.getUsers({ creds: data.user });
      sendData({ user: data.user, info: res });
    } catch (e) {
      console.log(e);
      history.push("/");
    }
  };

  const delData = async () => {
    try {
      let res = await api.delUsers({ creds: data.user });
      sendData({ user: data.user, info: res });
    } catch (e) {
      console.log(e);
    }
  };

  return data.info.length >= 1 ? (
    <Container className="login-container">
      <Row>
        <Col>
          <img src={logo} className="signin-logo-stats" alt="" />
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <SimpleBar
            style={{ maxHeight: 300 }}
            forceVisible="n"
            autoHide={true}
          >
            <Table
              striped
              bordered
              hover
              variant="dark"
              size="sm"
              responsive="true"
              className="table-container"
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>UA</th>
                </tr>
              </thead>
              <tbody>
                {data.info.map((item, index) => (
                  <tr
                    onClick={() => {
                      setHighlight(index);
                    }}
                    key={index}
                  >
                    <td className="date-td">{item.date}</td>
                    <td className="time-td">{item.time} </td>
                    <td className="ua-td">{item.userUA}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </SimpleBar>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup className="user-list">
            <ListGroup.Item className="list-item-users">
              <i className="fas fa-at details-icons"></i>Email:{" "}
              {data.info[highlight].userEmail}
            </ListGroup.Item>
            <ListGroup.Item className="list-item-users">
              <i className="fas fa-user-edit details-icons"></i>Name:{" "}
              {data.info[highlight].userName}
            </ListGroup.Item>
            <ListGroup.Item className="list-item-users">
              <i className="fas fa-mobile-alt details-icons"></i>Phone:{" "}
              {data.info[highlight].userPhone}
            </ListGroup.Item>
            <ListGroup.Item className="list-item-users">
              <i className="fas fa-envelope details-icons"></i>Address:{" "}
              {data.info[highlight].userAddress}
            </ListGroup.Item>
            <ListGroup.Item className="list-item-users">
              <i className="fas fa-network-wired details-icons"></i>IP:{" "}
              {data.info[highlight].userIP}
            </ListGroup.Item>
            <ListGroup.Item className="list-item-users">
              <i className="fas fa-map-marker-alt details-icons"></i>GEO:{" "}
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${
                  data.info[highlight].userGeo.lat
                    ? data.info[highlight].userGeo.lat
                    : data.info[highlight].userGenGeo.ll[0]
                },${
                  data.info[highlight].userGeo.long
                    ? data.info[highlight].userGeo.long
                    : data.info[highlight].userGenGeo.ll[1]
                }`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {data.info[highlight].userGeo.lat &&
                data.info[highlight].userGeo.long
                  ? `${data.info[highlight].userGeo.lat},
                ${data.info[highlight].userGeo.long}`
                  : `Estimated: ${data.info[highlight].userGenGeo.ll[0]},${data.info[highlight].userGenGeo.ll[1]}`}
              </a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row className="button-row">
        <Col align="right">
          <Button className="clear-btn" onClick={delData}>
            CLEAR <i className="fas fa-trash-alt"></i>
          </Button>
        </Col>
        <Col align="left">
          <Button onClick={refreshData} className="refresh-btn">
            REFRESH <i className="fas fa-sync-alt"></i>
          </Button>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container className="login-container">
      <Row>
        <Col>
          <img src={logo} className="signin-logo-stats" alt="" />
        </Col>
      </Row>
      <Row className="loading-row">
        <Col>
          <i className="em em-thinking_face"></i>
        </Col>
      </Row>

      <Row className="button-row">
        <Col align="center">
          <Button onClick={refreshData} className="refresh-btn">
            REFRESH <i className="fas fa-sync-alt"></i>
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Stats;
