import { React, useState, useEffect } from "react";
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./Manager.css";
import logo from "./DAL/goalogo.png";
import api from "./DAL/api";

function Manager({ sendData }) {
  let history = useHistory();

  const [validInput, setValidInput] = useState(false);

  const [userInput, setInput] = useState({
    username: "",
    password: "",
  });

  const changeInput = (field, val) => {
    let newInput = userInput;
    if (field === "username") {
      setInput({ ...newInput, username: val });
    } else if (field === "password") {
      setInput({ ...newInput, password: val });
    }
  };

  useEffect(() => {
    if (userInput.username.length > 1 && userInput.password.length > 1) {
      setValidInput(true);
    } else if (
      userInput.username.length <= 1 ||
      userInput.password.length <= 1
    ) {
      setValidInput(false);
    }
  }, [userInput, validInput]);

  const handleLogin = async () => {
    try {
      let res = await api.getUsers({ creds: userInput });
      sendData({ user: userInput, info: res });
      history.push("/stats");
    } catch (e) {
      console.log(e);
      history.push("/");
    }
  };

  return (
    <Container fluid className="login-container">
      <Row>
        <Col>
          <img src={logo} className="signin-logo" alt="" />
        </Col>
      </Row>
      <Row className="signin-input-row">
        <Col align="center">
          <InputGroup className="mb-3 user-name-form ">
            <InputGroup.Text id="basic-addon1" className="input-icon  ">
              <i className="fas fa-user-cog"></i>
            </InputGroup.Text>
            <FormControl
              placeholder="المستخدم"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="submit-input "
              dir="rtl"
              onChange={(e) => {
                changeInput("username", e.target.value);
              }}
            />
          </InputGroup>
          <InputGroup className="mb-3 user-name-form ">
            <InputGroup.Text id="basic-addon1" className="input-icon  ">
              <i className="fas fa-key"></i>
            </InputGroup.Text>
            <FormControl
              placeholder="كلمة السر"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="submit-input "
              dir="rtl"
              onChange={(e) => {
                changeInput("password", e.target.value);
              }}
              type="password"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="signin-input-row">
        <Col>
          <button
            className="submit-btn"
            disabled={validInput ? false : true}
            onClick={handleLogin}
          >
            CONNECT <i className="fas fa-unlock-alt"></i>
          </button>
        </Col>
      </Row>
    </Container>
  );
}

export default Manager;
